<template>
    <div class="entdet__head">
      <div
        class="entdet__logo"
        v-if="entity.logo"
        :style="{backgroundImage: `url(${entity.logo})`}"></div>
      <div class="entdet__desc">
        <h1 class="entdet__title">{{entity.acronim}}</h1>
        <div>
          <span>{{entity.name}}</span>
        </div>
      </div>
      <div class="entdet__dates">
        <div>
          <span>{{$t('common.calification')}}:</span>
          <span v-if="entity?.id"><strong>{{getScore(entity)}}</strong></span>
        </div>
        <div>
          <span>{{$t('common.last_revision')}}:</span>
          <span v-if="report?.updated">{{filterDate(report.updated)}}</span>
        </div>
        <div>
          <span>{{$t('common.validator')}}:</span>
          <span>OpenODS SL</span>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportHeaderComponent',
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    report: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['challenges']),
  },
  methods: {
    filterDate(datestr) {
      const date = new Date(datestr);
      return `${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    getScore(entity) {
      if (!this.report) {
        return 0;
      }
      const blocks = this.challenges.reduce((acc, curr) => {
        const chall = { ...curr, isChecked: false };
        if (this.$t(`challenges_short.${entity.type}.${chall.name}`) !== '') {
          // Aggregate only challenges for the current entity type (-> has translation)
          if (Object.prototype.hasOwnProperty.call(acc, chall.phase)) acc[chall.phase].push(chall);
          else acc[chall.phase] = [chall];
        }
        return acc;
      }, {});
      const phases = Object.keys(blocks).sort().map((key) => blocks[key]);
      const entityChallenges = this.report.challenges.map((c) => c.challenge);
      const entityPhases = phases.map((phase) => ({
        value: phase.reduce((acc, curr) => (
          acc + entityChallenges.indexOf(curr.name) > -1 ? 1 : 0
        ), 0),
        total: phase.length,
      }));
      return Math.floor(100 * entityPhases.reduce((acc, curr) => (
        acc + ((10 * curr.value) / (curr.total * entityPhases.length))
      ), 0)) / 100;
    },
  },
};
</script>

<style lang="scss">
.entdet {
  margin-top: 20px;
  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  &__logo {
    flex: 1 1 100%;
    height: calc(50vw - 20px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    align-self: center;
  }
  &__desc {
    margin-top: 20px;
    flex: 1 1 100%;
    > div {
      display: flex;
    }
  }
  &__dates {
    display: block;
    width: 100%;
    margin-top: 6px;
    font-size: 14px;
    > div {
      display: flex;
      justify-content: space-between;
      span:first-child {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  &__title {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 460px) {
  .entdet {
    &__logo {
      flex: 0 0 140px;
      max-width: 140px;
      height: 88px;
      margin-right: 20px;
    }
    &__desc {
      flex: 0 1 calc(100% - 160px);
      margin-top: 0;
    }
    &__dates {
      display: none;
      flex: 1 0 100%;
    }
  }
}

@media screen and (min-width: 660px) {
  .entdet {
    &__desc {
      flex: 1 1 calc(100% - 430px);
    }
    &__dates {
      display: block;
      flex: 1 0 230px;
    }
  }
}
</style>
